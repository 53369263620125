<template>
  <v-row class="fill-height">
    <v-col xs="12" sm="12" md="12" lg="12" xl="12">
      <v-card>
        <v-card-title>
          <v-icon
            @click="pushRouteToView('benefits')"
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
          >
            mdi-arrow-left
          </v-icon>
          <v-icon class="mr-2">mdi-handshake</v-icon>
          Dados do Benefício
          <v-spacer></v-spacer>

          <v-btn
            outlined
            v-show="benefit.benefitStatusID==1"
            class="mx-1 btn-secondary"
            @click="requestTicket()"
          >
            <v-icon left>mdi-barcode-scan</v-icon>
            Finalizar e Solicitar Boleto
          </v-btn>

          <v-btn
            outlined
            v-show="benefit.benefitStatusID==2"
            class="mx-1 btn-secondary"
            @click="downloadTicket()"
          >
            <v-icon left>mdi-file-download-outline</v-icon>
            Download Boleto
          </v-btn>

          <v-btn
            outlined
            v-show="benefit.benefitStatusID==2"
            class="mx-1 btn-save"
            @click="confirmPayment()"
          >
            <v-icon left>mdi-cash-check</v-icon>
            Confirmar Pagamento
          </v-btn>

          <v-btn
            outlined
            v-show="benefit.benefitStatusID != 4 "
            class="mx-1 btn-cancel"
            @click="cancelBenefit()"
          >
            <v-icon left>mdi-close</v-icon>
            Cancelar
          </v-btn>

          <v-btn
            v-show="benefit.benefitStatusID==1"
            
            dark
            :to="{ name: 'benefitEdit', params: { id: benefit.id } }"
            class="ml-1 btn-edit"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

        </v-card-title>
        <v-card-text class="text-data-visualization">
          <v-row>
            <v-col md="12" sm="6"><b>Descrição:</b> {{ benefit.desc }}</v-col>
          </v-row>
          <v-row>
            <v-col md="4" sm="6">
              <b>Status:</b> {{ benefit.benefitStatus }}
            </v-col>
            <v-col md="4" sm="6">
              <b>Valor:</b> {{ benefit.value | money }}
            </v-col>
            <v-col md="4" sm="6">
              <b>Data:</b>
              {{ benefit.date | formatDate2 }}
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12">
              <v-card-title class="pl-0"> Usuários Beneficiados</v-card-title>
            </v-col>
          </v-row>
        </v-card-text>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="benefit.benefitUsers"
          item-key="id"
          v-show="benefit.benefitUsers"
        >
          <template v-slot:item.value="{ item }">
            {{ item.value | money }}
          </template>
          <template v-slot:item.additionalValue="{ item }">
            <number-input
              class="my-2"
              label="Valor Adicional"
              :disabled="benefit.benefitStatus !== 'Aberto'"
              v-model.number="item.additionalValue"
              placeholder="Informe o motivo"
              :rules="textRules"
              outlined
              dense
              hide-details
              :options="optionsMoney"
              v-show="benefit.benefitStatusID == 1"
            ></number-input>
            <span v-show="benefit.benefitStatusID != 1">{{ item.additionalValue | money }}</span>
          </template>

          <template v-slot:item.additionalReason="{ item }">
            <v-text-field
              :disabled="benefit.benefitStatus !== 'Aberto'"
              class="my-2"
              dense
              v-model="item.additionalReason"
              label="Motivo"
              outlined
              hide-details
              v-show="benefit.benefitStatusID == 1"
            ></v-text-field>
            <span v-show="benefit.benefitStatusID != 1">{{ item.additionalReason }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="benefit.benefitStatus !== 'Aberto'"
                  icon
                  @click="updateBenefitUsers(item)"
                  v-bind="attrs"
                  v-on="on"
                  v-show="benefit.benefitStatusID == 1"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <span></span>
              </template>
              <span>Confirmar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import NumberInput from "@/components/NumberInput";
import store from "@/store";
import {
  BENEFIT_CANCEL,
  BENEFIT_CONFIRM_PAYMENT, BENEFIT_DOWNLOAD_TICKET,
  BENEFIT_EDIT_USERS,
  BENEFIT_GET,
  BENEFIT_REQUEST_TICKET
} from "@/store/actions/benefit.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";
// import { BENEFIT_RESET_STATE } from "@/store/mutations/benefit.type";

export default {
  name: "benefit",
  components: {NumberInput},
  data: () => ({
    selected: [],
    optionsMoney: {
      locale: "pt-BR",
      prefix: "R$:",
      length: 7,
      precision: 2,
    },
    headers: [
      {text: "Nome", value: "name"},
      {text: "CPF", value: "cpf"},
      {text: "Categorias", value: "categories"},
      {text: "Valor", value: "value"},
      {text: "Valor Adicional", value: "additionalValue", width: 150},
      {text: "Motivo Adicional", value: "additionalReason"},
      {text: "Editar", value: "actions", sortable: false},
    ],
    textRules: [(v) => !!v || "Este campo é obrigatório"],
  }),

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    //await store.commit(BENEFIT_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      //store.commit(BENEFIT_RESET_STATE),
      store.dispatch(BENEFIT_GET, to.params.id),
    ]).then(() => {
      next();
    });
  },

  methods: {
    updateBenefitUsers(item) {
      let dt = {
        id: item.id,
        additionalValue: item.additionalValue,
        additionalReason: item.additionalReason,
      }
      store
        .dispatch(BENEFIT_EDIT_USERS, dt)
        .then((resp) => {
          this.snackbar.snackbar = true;
          this.snackbar.text = "Benefício Editado com sucesso";
          this.snackbar.color = "success";
          // this.$router.push({ name: "benefits" });
          this.benefit.value = resp.data.value
        })
        .catch((err) => {
          this.snackbar.snackbar = true;
          this.snackbar.text = `Não foi possível editar informações - ${err.status}`;
          this.snackbar.color = "error";
        });
    },

    cancelBenefit() {
      this.$store.commit(CHANGE_PROGRESS, true)
      store
        .dispatch(BENEFIT_CANCEL, this.benefit.id)
        .then((resp) => {
          this.snackbar.snackbar = true;
          this.snackbar.text = "Benefício Editado com sucesso";
          this.snackbar.color = "success";
          // this.$router.push({ name: "benefits" });
          this.benefit.benefitStatusID = resp.data.id
          this.benefit.benefitStatus = resp.data.status
          this.$store.commit(CHANGE_PROGRESS, false)
        })
        .catch((err) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.text = `Não foi possível alterar status - ${err.status}`;
          this.snackbar.color = "error";
        });
    },

    requestTicket() {
      this.$store.commit(CHANGE_PROGRESS, true)
      store
        .dispatch(BENEFIT_REQUEST_TICKET, this.benefit.id)
        .then((resp) => {
          this.snackbar.snackbar = true;
          this.snackbar.text = "Benefício Editado com sucesso";
          this.snackbar.color = "success";
          // this.$router.push({ name: "benefits" });
          this.benefit.benefitStatusID = resp.data.id
          this.benefit.benefitStatus = resp.data.status
          this.$store.commit(CHANGE_PROGRESS, false)
        })
        .catch((err) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.text = `Não foi possível alterar status - ${err.status}`;
          this.snackbar.color = "error";
        });
    },
    downloadTicket() {
      this.$store.commit(CHANGE_PROGRESS, true)
      store
        .dispatch(BENEFIT_DOWNLOAD_TICKET, this.benefit.id)
        .then((response) => {
          this.snackbar.snackbar = true;
          this.snackbar.text = "Benefício Editado com sucesso";
          this.snackbar.color = "success";

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.benefit.desc+'.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          
          this.$store.commit(CHANGE_PROGRESS, false)
        })
        .catch((err) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.text = `Não foi possível alterar status - ${err.status}`;
          this.snackbar.color = "error";
        });
    },
    confirmPayment() {
      this.$store.commit(CHANGE_PROGRESS, true)
      store
        .dispatch(BENEFIT_CONFIRM_PAYMENT, this.benefit.id)
        .then((resp) => {
          this.snackbar.snackbar = true;
          this.snackbar.text = "Benefício Editado com sucesso";
          this.snackbar.color = "success";
          // this.$router.push({ name: "benefits" });
          this.benefit.benefitStatusID = resp.data.id
          this.benefit.benefitStatus = resp.data.status
          this.$store.commit(CHANGE_PROGRESS, false)
        })
        .catch((err) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.text = `Não foi possível alterar status - ${err.status}`;
          this.snackbar.color = "error";
        });
    },
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
  },

  computed: {
    ...mapGetters(["benefit", "checkUsers", "snackbar"]),
  },
};
</script>
